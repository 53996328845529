import _, { debounce } from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import FindHospitalView from './find-hospital-view'

const FindHospitalController = () => {
  const [loading, setLoading] = useState(true)
  const { i18n } = useTranslation()
  const [hospitals, setHospitals] = useState([])
  const [cities, setCities] = useState([])
  const [city, setCity] = useState(null)
  const [types, setTypes] = useState(null)
  const [type, setType] = useState(null)
  const [page, setPage] = useState(1)
  const [restItems, setRestItems] = useState(0)

  const [filters, setFilters] = useState({
    provider_type: null,
    city: null,
    query: null,
    page: 1,
  })

  const mapDataHospital = (hos) => _.map(hos, (item) => ({
    city: item.city,
    providerType: item.provider_type,
    providerName: item.provider_name,
    address: item.address,
    phone: item.phone,
    inPatient: item.in_patient === 0 ? 'N' : 'Y',
    outPatient: item.out_patient === 0 ? 'N' : 'Y',
  }))

  const getHospitals = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'claims-support/hospitals-clinics',
      params: {
        ...filters,
        locale: i18n.language,
      },
    })
      .then(({ data }) => {
        setHospitals([...hospitals, ...mapDataHospital(data.data)])
        setCities(data.city)
        setTypes(data.provider_type)
        setLoading(false)
        setRestItems(data.rest_count)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onHandleFilterType = (t) => {
    setHospitals([])
    setType(t.value)
    let updatedValue = {}
    updatedValue = {
      provider_type: _.toLower(t.value),
      page: 1,
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleFilterCity = (ct) => {
    setHospitals([])
    setCity(ct.value)
    let updatedValue = {}
    updatedValue = {
      city: _.toLower(ct.value),
      page: 1,
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleMore = () => {
    let updatedValue = {}
    updatedValue = { page: page + 1 }
    setPage(page + 1)
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleFilterByQuery = async (e) => {
    setHospitals([])
    let updatedValue = {}
    updatedValue = {
      query: e.target.value,
      page: 1,
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }
  const debouncedChangeHandler = useCallback(
    debounce(onHandleFilterByQuery, 500),
    [],
  )

  useEffect(() => {
    getHospitals()
  }, [filters, i18n.language])

  useEffect(() => {
    getHospitals()
  }, [])

  const viewProps = {
    data: hospitals,
    loading,
    cities,
    types,
    type,
    city,
    restItems,
    onHandleFilterType,
    onHandleFilterCity,
    onHandleMore,
    debouncedChangeHandler,
  }
  return <FindHospitalView {...viewProps} />
}

export default FindHospitalController
